import * as React from "react"
import {Helmet} from "react-helmet";
import {Link, graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {OutboundLink} from "gatsby-plugin-google-gtag"

import "../styles/index.scss";

const GalleryItem = ({item}) => {
    const image = getImage(item.image);
    return (
        <div className={"gallery-card-wrapper"}>
            <div className={"gallery-card"}>
                <Link to={`/${item.id}`}>
                    <GatsbyImage
                        image={image}
                        alt={item.short_description}
                    />
                </Link>
                <div className={"gallery-card-metadata"}>
                    <div className={"gallery-card-title"}>
                        <Link to={`/${item.id}`}>
                            Wagyu No. {item.id}
                        </Link>
                    </div>
                    <div className={"gallery-card-action"}>
                        <OutboundLink href={`https://solsea.io/nft/${item.solana}`} target={"_blank"} rel={"noreferrer"} className={"action"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                            </svg>
                        </OutboundLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

const IndexPage = ({data}) => {
    return (
        <main id={"page"}>
            <Helmet>
                <title>The Wagyu Gallery</title>
            </Helmet>
            <h1 id={"webtitle"}>
                The Wagyu Gallery
            </h1>
            {data.allSeriesYaml.nodes.map(({series}) => (
                <div key={series.id} className={"series-wrapper"}>
                    <div className={"series-title"}>{series.name}</div>
                    <div className={"series-gallery"}>
                        {data.allCollectionYaml.nodes.map(({collection}) => collection.series_id === series.id ? <GalleryItem key={collection.id} item={collection}/> : null)}
                    </div>
                </div>
            ))}
            <footer>
                <div>
                    Copyright © 2021 The Wagyu Gallery. All rights reserved.
                </div>
                <div>
                    <OutboundLink href={"https://solsea.io/collection/614dbceff0a1075fd4c8a1de"}>Solsea</OutboundLink> | <OutboundLink href={"https://twitter.com/WagyuGallery"}>Twitter</OutboundLink>
                </div>
            </footer>
        </main>
    )
}

export default IndexPage;

export const query = graphql`
    query {
        allSeriesYaml {
            nodes {
                series {
                    id
                    name
                }
            }
        }
        allCollectionYaml {
            nodes {
                collection {
                    id
                    series_id
                    short_description
                    full_description
                    solana
                    ipfs
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 320)
                        }
                    }
                }
            }
        }
    }
`;